import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      font-size: 16px;
    }

    html, body {
        height: 100%;
        background-color: #f6f9fc;
        font-family: 'Poppins', sans-serif;

        h1 {
          color: #1c0950;
          font-size: 5rem;
          font-weight: 600rem;
        }

        h2 {
          color: #1c0950;
          font-size: 3rem;
          text-align: center;
          margin-bottom: 2rem;
          font-weight: 600rem;
        }

        p {
          color: #1c0950;
          font-weight: 300;
          line-height: 2rem;
          font-size: 1.35rem;
        }

        img {
          max-width: 100%;
        }
    }
`;

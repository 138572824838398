export default Object.freeze({
  HOME: {
    name: "Home",
    route: "/",
  },
  ABOUT: {
    name: "About me",
    route: "/about",
  },
  BLOG: {
    name: "Blog",
    route: "/blog",
  },
  CONTACTS: {
    name: "Contacts",
    route: "/contacts",
  },
});

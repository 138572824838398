import styled from "styled-components";

export const HomeStyled = styled.section`
  section {
    &.main-block {
      display: flex;
      overflow: hidden;
      align-items: center;
      min-height: 37.5rem;
      background-size: cover;
      justify-content: center;
      margin: 0 1rem 1rem 1rem;
      height: calc(100vh - 7rem);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: ${({ theme }) => theme.borderRadius.br24};
      background-image: url("/assets/backgrounds/homepage-gradient.jpg");

      h1 {
        font-size: 5rem;
        font-weight: 600;
        text-align: center;
        color: ${({ theme }) => theme.colors.white};

        span {
          display: block;
          font-weight: 300;
          font-size: 1.25rem;
        }
      }
    }

    &.about-block {
      display: flex;
      margin: 9rem 0;
      flex-wrap: wrap;
      justify-content: center;

      div {
        width: 70%;

        h2 {
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }

    &.i-block {
      margin: 1rem;

      img {
        border-radius: ${({ theme }) => theme.borderRadius.br24};
      }
    }

    &.stack-block {
      margin: 9rem 0;

      > div {
        display: grid;
        gap: 0px 1rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        div {
          padding: 3rem 0;
          text-align: center;
          margin-bottom: 1rem;
          border-radius: 0.5rem;

          /* &:nth-child(-4 + n) {
            margin-bottom: 0;
          } */
        }
      }
    }
  }
`;

import theme from "src/shared/styles/theme";
import Header from "src/shared/components/Header/Header";
import { GeneralLayoutProps } from "./interface";
import FullWidthSection from "src/shared/components/GridSystem/FullWidthSection";

export default function GeneralLayout({ children }: GeneralLayoutProps) {
  return (
    <div className="page-container">
      {/* <Header /> */}
      <main>{children}</main>
    </div>
  );
}

import GeneralLayout from "src/layout/GeneralLayout";
import FullWidthSection from "src/shared/components/GridSystem/FullWidthSection";
import { Row } from "src/shared/components/GridSystem/Row";
import { HomeStyled } from "./styles";

export default function Home() {
  return (
    <GeneralLayout>
      <HomeStyled>
        <section className="main-block">
          <h1>
            <span>Experienced Front-end Engineer</span>
            Taras Oliynyk
          </h1>
        </section>
      </HomeStyled>
    </GeneralLayout>
  );
}

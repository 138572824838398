import ReactDOM from "react-dom/client";
import App from "./screens/App/components/App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as Element | DocumentFragment
);

root.render(<App />);
reportWebVitals();
